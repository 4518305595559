//
//
//
//
//
//
//
//

import {Pie, Plot,} from '@antv/g2plot'
import ChartCard from './card'
import mixins from './mixins'
export default {
  name: "ChartPie",
  components: {
    ChartCard,
  },
  props: {
    radius: {
      type: Number,
      default: .75,
    },
    interactions: {
      type: Array,
      default: () => [{ type: 'element-selected' }, { type: 'element-active' }],
    },
  },
  mixins: [mixins],
  methods: {
    renderChart() {
      if(this.chartRef instanceof Plot) {
        this.chartRef.changeData(this.data)
      } else {
        this.chartRef = new Pie(this.$refs.root, {
          data: this.data,
          angleField: this.yField,
          colorField: this.xField,
          radius: this.radius,
          label: this.label ?? {
            type: 'spider',
            labelHeight: 28,
            content: '{name}\n{percentage}',
          },
          interactions: this.interactions,
          meta: this.meta,
        })
        /*https://g2plot.antv.vision/zh/docs/api/options/events/#gatsby-focus-wrapper*/
        this.chartRef.on('element:click', e => void this.$emit('elementClick', e))
        this.chartRef.render()
      }
    },
  },
}
