export default {
  props: {
    title: {
      type: String,
    },
    data: {
      type: Array,
    },
    xField: {
      type: String,
      default: "Key",
    },
    yField: {
      type: String,
      default: "Value",
    },
    /*
            https://g2plot.antv.vision/zh/docs/api/components/label#gatsby-focus-wrapper
        */
    label: {
      type: Object,
    },
    meta: {
      type: Object,
    },
  },
  data: () => ({
    chartRef: null,
  }),
  watch: {
    data(now) {
      if (!(now instanceof Array)) return;
      // if (now.length <= 0) return;

      this.renderChart();
    },
  },
  beforeDestroy() {
    this.chartRef?.destroy();
  },
};
