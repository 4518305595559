//
//
//
//
//
//
//

export default {
  name: "ChartCard",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
